<template>
  <div style="width: 100%" class="fill-height">
    <v-btn
      style="z-index: 4"
      rounded
      fixed
      bottom
      right
      color="primary"
      @click="openAddPrinter"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-icon>mdi-plus</v-icon>
      <span>{{ $t(`$vuetify.company.addPrinterBtn`) }}</span>
    </v-btn>
    <v-container>
      <v-row style="margin-top: 40px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card
            v-for="(printer, i) in sortedPrinters"
            :key="i"
            hover
            class="pb-2 mb-2"
          >
            <v-row class="ma-0 pa-0">
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="caption grey--text text-center">Naziv printera</div>
                <div class="text-center">{{ printer.name }}</div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="3"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
              >
                <div class="caption grey--text text-center">
                  Adresa printera
                </div>
                <div class="text-capitalize text-center">
                  {{ printer.ip_address }}
                </div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
              >
                <div class="caption grey--text text-center">
                  Naziv printera na mreži
                </div>
                <div class="text-capitalize text-center">
                  {{ printer.printer_name }}
                </div>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                md="2"
                lg="2"
                xl="2"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
              >
                <div class="caption grey--text text-center">Library</div>
                <div class="text-capitalize text-center">
                  {{ printer.library }}
                </div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="3"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
              >
                <div class="d-flex justify-end align-center pl-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0 mr-5"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="deletePrinter(printer)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Brisanje printera</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="testPrint(printer)"
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>Testni ispis</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="openEditPrinter(printer)"
                        @keypress.enter="openEditPrinter(printer)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi artikl</span>
                  </v-tooltip>
                </div></v-col
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <add-printer ref="addPrinter"></add-printer>
      <edit-printer ref="editPrinter"></edit-printer>
      <add-jspm-printer ref="addJspmPrinter"></add-jspm-printer>
      <edit-jspm-printer ref="editJspmPrinter"></edit-jspm-printer>
    </v-container>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import state from '@/state'
import AddPrinter from '@/modules/point-of-sale/components/printer-configuration/AddPrinter'
import EditPrinter from '@/modules/point-of-sale/components/printer-configuration/EditPrinter'
import AddJspmPrinter from '@/modules/point-of-sale/components/printer-configuration/AddJspmPrinter'
import EditJspmPrinter from '@/modules/point-of-sale/components/printer-configuration/EditJspmPrinter'
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import applicationSettings from '@/mixins/applicationSettings'
import { initPrintManager } from '@/libs/jsPrintManager/utils'
import { testPrintJspm } from '@/libs/jsPrintManager/testPrint'
import { testPrintBixolon } from '@/libs/bixolon/testPrint.js'

export default {
  name: 'PrinterConfiguration',
  mixins: [applicationSettings],
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'confirm', 'confirmClose'],
  components: { AddPrinter, InfiniteLoading, EditPrinter, AddJspmPrinter, EditJspmPrinter },
  data: () => ({
    location: undefined,
    printers: [],
    sortedPrinters: [],
    listeners: [],
    selectedPrinter: null
  }),
  methods: {
    openAddPrinter () {
      this.$refs.addJspmPrinter.open()
    },
    openEditPrinter (printer) {
      this.$refs.editJspmPrinter.open(printer)
    },
    modifyPrinters (changedPrinter) {
      let found = false
      this.printers.forEach((printer, key) => {
        if (printer.id === changedPrinter.id) {
          found = true
          this.printers[key] = changedPrinter
        }
      })

      if (!found) {
        this.printers.push(changedPrinter)
      }
      this.sortedPrinters = clone(this.printers.sort((a, b) => a.name.localeCompare(b.name)))
    },
    infiniteHandler ($state) {
      if (state.getPointOfSale().id !== '') {
        const query = df.collection(`location_printers/${state.getPointOfSale().id}/printers`)
        const listener = query
          .onSnapshot((doc) => {
            doc.docs.forEach((rec) => {
              this.modifyPrinters(rec.data())
            })
            $state.complete()
          })
        this.listeners.push(listener)
      } else {
        $state.complete()
      }
    },
    testPrint (printer) {
      this.selectedPrinter = printer
      if (this.selectedPrinter.library === 'UNIVERSAL') {
        this.printWithUniversal()
      } else if (!this.selectedPrinter.library || this.selectedPrinter.library === 'BIXOLON') {
        this.printWithBixolon()
      }
    },
    async deletePrinter (printer) {
      const docRef = df.doc(`location_printers/${state.getPointOfSale().id}/printers/${printer.id}`)
      this.confirm({
        title: 'Brisanje printera',
        message: `Jeste li sigurni da želite obrisati ${printer.name}?`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: this.$t('$vuetify.yes'),
          cancelText: this.$t('$vuetify.no')
        }
      }).then(async (resp) => {
        try {
          this.confirmClose()
          if (resp) {
            await docRef.delete()
            const printerIndex = this.printers.findIndex(p => p.id === printer.id)
            if (printerIndex > -1) {
              this.printers.splice(printerIndex, 1)
            }
            const sortedPrinterIndex = this.sortedPrinters.findIndex(p => p.id === printer.id)
            if (sortedPrinterIndex > -1) {
              this.sortedPrinters.splice(sortedPrinterIndex, 1)
            }
          }
        } catch (err) {
          console.error('Error deleting printer: ', err)
          this.confirmClose()
        }
      })
    },

    successHook () {
      // SUCCESS: Proceed to printing
      this.showLoader()
      testPrintJspm(this.selectedPrinter, this.hideLoader)
    },

    failureHook () {
      // FAIL: Cannot print
      console.error('Error printing')
    },

    async printWithUniversal () {
      initPrintManager(this.successHook, this.failureHook)
    },

    printWithBixolon () {
      this.showLoader()
      testPrintBixolon(this.selectedPrinter, this.hideLoader)
    }
  }
}
</script>
