<template>
  <div>
    <v-dialog
      v-model="visible"
      @keydown.esc="close"
      persistent
      scrollable
      max-width="800"
      style="z-index: 6"
    >
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>{{ $t("$vuetify.printerConfig.installedPrinters") }}</v-tab>
          <v-tab>{{ $t("$vuetify.printerConfig.networkPrinters") }}</v-tab>
          <v-tab>Bixolon</v-tab>
          <v-tab-item>
            <installed-printers
              @addPrinter="onAddPrinter"
              @close="close"
            ></installed-printers>
          </v-tab-item>
          <v-tab-item>
            <network-printers
              @addPrinter="onAddPrinter"
              @close="close"
            ></network-printers>
          </v-tab-item>
          <v-tab-item>
            <bixolon-printers @addPrinter="onAddPrinter" @close="close">
            </bixolon-printers>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import * as JSPM from 'jsprintmanager'
import { df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
// import { jspmWSStatus } from '@/libs/jsPrintManager/utils.js'
import InstalledPrinters from '@/modules/point-of-sale/components/printer-configuration/Jspm/InstalledPrinters.vue'
import NetworkPrinters from '@/modules/point-of-sale/components/printer-configuration/Jspm/NetworkPrinters.vue'
import BixolonPrinters from '@/modules/point-of-sale/components/printer-configuration/Jspm/BixolonPrinters.vue'
export default {
  components: {
    InstalledPrinters, NetworkPrinters, BixolonPrinters
  },
  data () {
    return {
      visible: false,
      tab: null,
      printers: []
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    },

    async getPrintersFromDatabase () {
      const printersRef = await df.collection(`location_printers/${state.getPointOfSale().id}/printers`).get()
      this.printers = printersRef.docs.map(doc => doc.data())
    },

    async onAddPrinter (printer) {
      await this.getPrintersFromDatabase()

      if (this.printers.findIndex(p => p.name === printer.name) === -1) {
        const printerId = uuidv4()
        const docRef = df.doc(`location_printers/${state.getPointOfSale().id}/printers/${printerId}`)

        try {
          await docRef.set(
            {
              id: printerId,
              name: printer.name,
              ip_address: printer.ipAddress,
              port: printer.port,
              printer_name: printer.printerName,
              library: printer.library,
              font: printer.font,
              size: printer.size,
              width: printer.width,
              line_length: printer.lineLength,
              char_table: printer.charTable,
              code_page: printer.codePage,
              euro_symbol_type: printer.euroSymbolType
            }
          )
          console.log('Printer successfully saved.')
        } catch (err) {
          console.error('Error saving printer: ', err)
        }
      }
      this.close()
    }
  },
  mounted () {
    // this.initPrintManager()
    this.getPrintersFromDatabase()
  }
}
</script>

<style>
</style>
