/* eslint-disable no-unused-vars */

import * as JSPM from 'jsprintmanager'
import { reprint } from '@/libs/bixolon/reprint'
import json from '@/libs/receiptIssuing/testReceipt/testReceipt.json'
import { print58 } from '@/libs/bixolon/print58'
import { loadScript, unloadScript } from 'vue-plugin-load-script'
import state from '@/state'

const cpTableUri = 'https://firebasestorage.googleapis.com/v0/b/ai-fisk-stage/o/javascript%2Fcptable.js?alt=media&token=2bbbaafa-5866-4acd-8e7f-7ad119399288'
const cpUtilsUri = 'https://firebasestorage.googleapis.com/v0/b/ai-fisk-stage/o/javascript%2Fcputils.js?alt=media&token=aa7abbec-5afa-43d1-b052-e0df13527090'
const jspmPosUri = 'https://firebasestorage.googleapis.com/v0/b/ai-fisk-stage/o/javascript%2FJSESCPOSBuilder.js?alt=media&token=711f55f8-3128-473c-9e83-52c472ac0a42'

export const testPrintJspm = async (printer, hideLoader) => {
  loadScripts(doPrint, printer, hideLoader)
}

const doPrint = (printer, hideLoader) => {
  state.setPrinterLib('UNIVERSAL')
  if (printer.width === '80') {
    reprint(printer, json, 2)
  } else if (printer.width === '58') {
    print58(printer, json, 2)
  }
  hideLoader()
}

const loadScripts = (callback, printer, hideLoader) => {
  loadScript(cpTableUri)
    .then(() => {
      console.info('cptable loaded.')
      loadScript(cpUtilsUri)
        .then(() => {
          console.info('cputils loaded.')
          loadScript(jspmPosUri)
            .then(() => {
              console.info('JSESCPOSBuilder loaded.')
              callback(printer, hideLoader)
            })
            .catch(() => {
              console.error('JSESCPOSBuilder.js failed to load.')
            })
        })
        .catch(() => {
          console.error('cputils.js failed to load.')
        })
    })
    .catch(() => {
      console.error('cptable.js failed to load.')
    })
}

const unloadScripts = () => {
  unloadScript(jspmPosUri)
    .then(() => {
      console.info('JSESCPOSBuilder unloaded.')
      unloadScript(cpUtilsUri)
        .then(() => {
          console.info('cputils unloaded.')
          unloadScript(cpTableUri)
            .then(() => {
              console.info('cptable unloaded.')
            })
            .catch(() => {
              console.error('cptable.js failed to unload.')
            })
        })
        .catch(() => {
          console.error('cputils.js failed to unload.')
        })
    })
    .catch(() => {
      console.error('JSESCPOSBuilder.js failed to unload.')
    })
}
