<template>
  <div>
    <installed-printer-edit
      ref="installedEdit"
      @editPrinter="onEditPrinter"
    ></installed-printer-edit>
    <network-printer-edit
      ref="networkEdit"
      @editPrinter="onEditPrinter"
    ></network-printer-edit>
    <bixolon-printer-edit
      ref="bixolonEdit"
      @editPrinter="onEditPrinter"
    ></bixolon-printer-edit>
  </div>
</template>

<script>
import { df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
import BixolonPrinterEdit from '@/modules/point-of-sale/components/printer-configuration/Jspm/BixolonPrinterEdit.vue'
import InstalledPrinterEdit from '@/modules/point-of-sale/components/printer-configuration/Jspm/InstalledPrinterEdit.vue'
import NetworkPrinterEdit from '@/modules/point-of-sale/components/printer-configuration/Jspm/NetworkPrinterEdit.vue'
export default {
  components: {
    BixolonPrinterEdit, InstalledPrinterEdit, NetworkPrinterEdit
  },
  data () {
    return {
      visible: false,
      tab: null,
      printers: [],
      printerToEdit: null
    }
  },
  computed: {
    isInstalled () {
      return this.printerToEdit && this.printerToEdit.library === 'UNIVERSAL' && !this.printerToEdit.ip_address
    },
    isNetwork () {
      return this.printerToEdit && this.printerToEdit.library === 'UNIVERSAL' && this.printerToEdit.ip_address
    },
    isBixolon () {
      return this.printerToEdit && (!this.printerToEdit.library || this.printerToEdit.library === 'BIXOLON')
    }
  },
  methods: {
    open (printer) {
      this.printerToEdit = printer
      this.visible = true
      // this.$refs.installed.open(printer)
      if (this.isInstalled) {
        this.$refs.installedEdit.open(this.printerToEdit)
      } else if (this.isNetwork) {
        this.$refs.networkEdit.open(this.printerToEdit)
      } else if (this.isBixolon) {
        this.$refs.bixolonEdit.open(this.printerToEdit)
      }
    },
    close () {
      this.visible = false
    },

    async getPrintersFromDatabase () {
      const printersRef = await df.collection(`location_printers/${state.getPointOfSale().id}/printers`).get()
      this.printers = printersRef.docs.map(doc => doc.data())
    },

    async onAddPrinter (printer) {
      await this.getPrintersFromDatabase()

      if (this.printers.findIndex(p => p.name === printer.name) === -1) {
        const printerId = uuidv4()
        const docRef = df.doc(`location_printers/${state.getPointOfSale().id}/printers/${printerId}`)

        try {
          await docRef.set(
            {
              id: printerId,
              name: printer.name,
              ip_address: printer.ipAddress,
              port: printer.port,
              printer_name: printer.printerName,
              library: printer.library,
              font: printer.font,
              size: printer.size,
              width: printer.width,
              line_length: printer.lineLength
            }
          )
          console.log('Printer successfully saved.')
        } catch (err) {
          console.error('Error saving printer: ', err)
        }
      }
      this.close()
    },
    async onEditPrinter (printer) {
      const docRef = df.doc(`location_printers/${state.getPointOfSale().id}/printers/${printer.id}`)

      try {
        await docRef.set(
          {
            id: printer.id,
            name: printer.name,
            ip_address: printer.ipAddress,
            port: printer.port,
            printer_name: printer.printerName,
            library: printer.library,
            font: printer.font,
            size: printer.size,
            width: printer.width,
            line_length: printer.lineLength,
            char_table: printer.charTable,
            code_page: printer.codePage,
            euro_symbol_type: printer.euroSymbolType
          }
        )
        console.log('Printer successfully saved.')
      } catch (err) {
        console.error('Error saving printer: ', err)
      }
    }
  },
  mounted () {
    // this.initPrintManager()
    this.getPrintersFromDatabase()
  }
}
</script>
