<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addPrinterForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Printer</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text v-if="printer">
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    :label="$t('$vuetify.printerConfig.printerName')"
                    autofocus
                    v-model="printer.name"
                    class="pt-1"
                    :rules="[rules.req]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -2rem">
                <v-col>
                  <v-text-field
                    class="pt-1"
                    dense
                    outlined
                    :label="$t('$vuetify.printerConfig.ipAddress')"
                    v-model="printer.ip_address"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -2rem">
                <v-col>
                  <v-text-field
                    class="pt-1"
                    dense
                    outlined
                    :label="$t('$vuetify.printerConfig.networkPrinterName')"
                    v-model="printer.printer_name"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -2rem">
                <v-col>
                  <v-select
                    class="pt-1"
                    dense
                    outlined
                    v-model="printer.font"
                    :items="fonts"
                    :label="$t('$vuetify.printerConfig.font')"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    class="pt-1"
                    dense
                    outlined
                    v-model="printer.size"
                    :items="sizes"
                    :label="$t('$vuetify.printerConfig.size')"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="pt-1"
                    dense
                    outlined
                    v-model="printer.line_length"
                    :label="$t('$vuetify.printerConfig.lineLength')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi izmjene
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import rules from '@/plugins/rules'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    location: undefined,
    submitting: false,
    printer: undefined,
    fonts: ['A', 'B', 'C'],
    sizes: [0, 1, 2, 3],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  mounted () {
  },
  methods: {
    open (locationId, printer) {
      this.printer = printer
      this.visible = true
      this.submitting = false
      this.locationId = locationId
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.printer = undefined
      this.visible = false
      this.location = undefined
    },
    async submit () {
      if (!this.printer) {
        return
      }
      const printerId = this.printer.id
      const docRef = df.doc(`location_printers/${this.locationId}/printers/${printerId}`)

      try {
        await docRef.set(
          {
            id: printerId,
            name: this.printer.name,
            ip_address: this.printer.ip_address,
            printer_name: this.printer.printer_name,
            font: this.printer.font,
            size: this.printer.size,
            line_length: this.printer.line_length,
            library: this.printer.library
          }
        )
        console.info('Printer successfully saved.')
      } catch (err) {
        console.error('Error saving printer: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
