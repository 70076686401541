<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          class="pt-1"
          dense
          outlined
          :label="$t('$vuetify.printerConfig.printerName')"
          autofocus
          v-model="settings.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="margin-top: -2rem">
        <v-text-field
          class="pt-1"
          dense
          outlined
          :label="$t('$vuetify.printerConfig.ipAddress')"
          v-model="settings.ipAddress"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="margin-top: -2rem">
        <v-text-field
          class="pt-1"
          dense
          outlined
          :label="$t('$vuetify.printerConfig.networkPrinterName')"
          v-model="settings.printerName"
        >
        </v-text-field>
      </v-col> </v-row
    ><v-row>
      <v-col cols="3" style="margin-top: -2rem">
        <v-select
          class="pt-1"
          dense
          outlined
          v-model="settings.width"
          :items="widths"
          :label="$t('$vuetify.printerConfig.width')"
          @change="calculateLineLength"
        >
        </v-select>
      </v-col>
      <v-col cols="3" style="margin-top: -2rem">
        <v-select
          :disabled="isA4"
          class="pt-1"
          dense
          outlined
          v-model="settings.font"
          :items="fonts"
          :label="$t('$vuetify.printerConfig.font')"
          @change="calculateLineLength"
        >
        </v-select>
      </v-col>
      <v-col cols="3" style="margin-top: -2rem">
        <v-select
          :disabled="isA4"
          class="pt-1"
          dense
          outlined
          v-model="settings.size"
          :items="sizes"
          :label="$t('$vuetify.printerConfig.size')"
          @change="calculateLineLength"
        >
        </v-select>
      </v-col>
      <v-col cols="3" style="margin-top: -2rem">
        <v-text-field
          :disabled="isA4"
          class="pt-1"
          dense
          outlined
          v-model="settings.lineLength"
          :label="$t('$vuetify.printerConfig.lineLength')"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="right">
        <v-btn style="margin-right: 1rem" @click="close">{{
          $t("$vuetify.printerConfig.cancel")
        }}</v-btn>
        <v-btn :disabled="!valid" @click="addPrinter">{{
          $t("$vuetify.printerConfig.addPrinter")
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@/plugins/rules'

export default {
  data () {
    return {
      name: undefined,
      ipAddress: undefined,
      printerName: undefined,
      settings: {
        name: '',
        ipAddress: '',
        port: '',
        printerName: '',
        font: '',
        size: '',
        lineLength: '',
        width: ''
      },
      rules: {
        req: rules.req(),
        eq: rules.eq
      },
      fonts: ['A', 'B', 'C'],
      sizes: [0, 1, 2, 3],
      widths: ['58', '80', 'A4'],
      charTable: null,
      codePage: null,
      euroSymbolType: null
    }
  },

  computed: {
    valid () {
      if (this.isA4) {
        return this.settings.name !== null && this.settings.printerName !== ''
      } else {
        return this.settings.name !== null &&
          this.settings.printerName !== '' &&
          this.settings.width !== '' &&
          this.settings.size !== '' &&
          this.settings.font !== '' &&
          this.settings.lineLength !== ''
      }
    },
    isA4 () {
      return this.settings.width === 'A4'
    }
  },

  watch: {
    settings: {
      immediate: true,
      deep: true,
      handler (newValue, _) {
        if (newValue.width === 'A4') {
          this.settings.font = ''
          this.settings.size = ''
          this.settings.lineLength = ''
        }
      }
    }
  },

  methods: {
    close () {
      this.clearForm()
      this.$emit('close')
    },

    clearForm () {
      this.settings.name = ''
      this.settings.ipAddress = 'https://localhost:18080/WebPrintSDK/'
      this.settings.printerName = ''
      this.settings.width = ''
      this.settings.font = ''
      this.settings.size = ''
      this.settings.lineLength = ''
    },

    addPrinter () {
      const printer = {
        name: this.settings.name,
        ipAddress: this.settings.ipAddress ?? null,
        printerName: this.settings.printerName,
        port: '',
        font: this.settings.font,
        size: this.settings.size,
        lineLength: this.settings.lineLength,
        width: this.settings.width,
        charTable: null,
        codePage: null,
        euroSymbolType: null,
        library: 'BIXOLON'
      }
      this.$emit('addPrinter', printer)
      this.clearForm()
    },

    calculateLineLength () {
      if (this.settings.font !== null && this.settings.size !== null) {
        switch (this.settings.font.toLowerCase()) {
          case 'a':
            switch (this.settings.size) {
              case 0:
                this.settings.lineLength = 48
                break
              case 1:
                this.settings.lineLength = 24
                break
              case 2:
                this.settings.lineLength = 16
                break
              case 3:
                this.settings.lineLength = 12
                break
            }
            break
          case 'b':
          case 'c':
            switch (this.settings.size) {
              case 0:
                this.settings.lineLength = 64
                break
              case 1:
                this.settings.lineLength = 32
                break
              case 2:
                this.settings.lineLength = 21
                break
              case 3:
                this.settings.lineLength = 16
                break
            }
            break
        }
      }
    }
  },
  created () {
    this.settings.ipAddress = 'https://localhost:18080/WebPrintSDK/'
  }
}
</script>
