/* eslint-disable no-unused-vars */
import { reprint } from '@/libs/bixolon/reprint'
import json from '@/libs/receiptIssuing/testReceipt/testReceipt.json'
import { print58 } from '@/libs/bixolon/print58'
import state from '@/state'

export const testPrintBixolon = async (printer, hideLoader) => {
  state.setPrinterLib('BIXOLON80')
  setTimeout(() => {
    if (!printer.width || printer.width === '80') {
      reprint(printer, json, 2)
    } else if (printer.width === '58') {
      print58(printer, json, 2)
    }
    hideLoader()
  }, 1000)

  // const [a, b] = [1, 1]
  // if (a === b) return

  // _hideLoader = hideLoader
  // printerInit(1)

  // let pName = printer.printer_name

  // printText('TEST PRINT\n\n', 1, 1, true, false, false, 0, 1)
  // printText('ABCČĆDDžĐEFGHIJKLLjMNNjOPRSŠTUVZŽ\n', 0, 0, false, false, 0, 0, 1)
  // printText('abcčćddžđefghijklljmnnjoprsštuvzž\n\n', 0, 0, false, false, 0, 0, 1)
  // printText('-'.repeat(printer.line_length) + '\n', 0, 0, false, false, false, 0, 0)

  // printText('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec nunc quam. Donec leo erat, placerat et convallis sit amet, placerat vel tellus. Fusce facilisis consequat risus.\n', 0, 0, false, false, false, 0, 0)

  // printText('-'.repeat(printer.line_length) + '\n', 0, 0, false, false, false, 0, 0)
  // print1DBarcode('01234567890', 0, 4, 70, 2, 1)
  // printQRCode('www.bixolon.com', 0, 1, 7, 0)
  // printText('\n\n\n\n\n', 0, 0, false, false, false, 0, 0)
  // cutPaper()

  // cutPaper()

  // let printerUrl
  // if (printer && printer.ip_address && printer.ip_address !== '') {
  //   printerUrl = printer.ip_address
  //   pName = printer.printer_name
  // }

  // var strSubmit = getPosData()

  // try {
  //   requestPrint(pName, strSubmit, viewResult, printerUrl)
  // } catch (err) {
  //   alert('Greška pri reprintu računa, molimo pokušajte ponovno!')
  //   console.error(err)
  // }
}

// const printerInit = (issueID) => {
//   checkPrinterStatus()
//   setCharacterset(1250)
//   setPosId(issueID)
// }

// const viewResult = (result) => {
//   if (result && result.length > 0) {
//     _hideLoader()
//   }
//   if (result === 'Cannot connect to server') {
//     alert('Pisač je nedostupan.')
//   }
// }
