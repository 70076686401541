<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="800"
    style="z-index: 6"
  >
    <v-card>
      <v-card-title>
        <h4 style="color: #6d6d6d">Uredi instalirani printer</h4>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col class="mt-1" cols="12">
            <v-text-field
              dense
              outlined
              :label="$t('$vuetify.printerConfig.printerName')"
              v-model="settings.name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="margin-top: -1.7rem">
            <v-select
              dense
              outlined
              v-model="settings.selectedPrinter"
              :items="installedPrinters"
              :label="$t('$vuetify.printerConfig.installedPrinter')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" style="margin-top: -2rem">
            <v-select
              class="pt-1"
              dense
              outlined
              v-model="settings.width"
              :items="widths"
              :label="$t('$vuetify.printerConfig.width')"
              @change="calculateLineLength"
            >
            </v-select>
          </v-col>
          <v-col cols="3" style="margin-top: -2rem">
            <v-select
              :disabled="isA4"
              class="pt-1"
              dense
              outlined
              v-model="settings.font"
              :items="fonts"
              :label="$t('$vuetify.printerConfig.font')"
              @change="calculateLineLength"
            >
            </v-select>
          </v-col>
          <v-col cols="3" style="margin-top: -2rem">
            <v-select
              :disabled="isA4"
              class="pt-1"
              dense
              outlined
              v-model="settings.size"
              :items="sizes"
              :label="$t('$vuetify.printerConfig.size')"
              @change="calculateLineLength"
            >
            </v-select>
          </v-col>
          <v-col cols="3" style="margin-top: -2rem">
            <v-text-field
              :disabled="isA4"
              class="pt-1"
              dense
              outlined
              v-model="settings.lineLength"
              :label="$t('$vuetify.printerConfig.lineLength')"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" style="margin-top: -2rem">
            <v-select
              :disabled="isA4"
              class="pt-1"
              outlined
              dense
              :items="charTables"
              item-text="name"
              item-value="value"
              v-model="charTable"
              label="Tablica znakova"
            ></v-select>
          </v-col>
          <v-col cols="4" style="margin-top: -2rem">
            <v-select
              :disabled="isA4"
              class="pt-1"
              outlined
              dense
              :items="codePages"
              item-text="name"
              item-value="value"
              v-model="codePage"
              label="Kodna stranica"
            >
            </v-select>
          </v-col>
          <v-col cols="4" style="margin-top: -2rem">
            <v-select
              :disabled="isA4"
              class="pt-1"
              outlined
              dense
              :items="euroSymbolTypes"
              item-text="name"
              item-value="value"
              v-model="euroSymbolType"
              label="Euro oznaka"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="right">
            <v-btn style="margin-right: 1rem" @click="close">{{
              $t("$vuetify.printerConfig.cancel")
            }}</v-btn>
            <v-btn :disabled="!valid" @click="editPrinter">{{
              $t("$vuetify.printerConfig.editPrinter")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import * as JSPM from 'jsprintmanager'
import { jspmWSStatus } from '@/libs/jsPrintManager/utils.js'
import state from '@/state'

export default {
  data () {
    return {
      visible: false,
      name: undefined,
      ipAddress: undefined,
      printerName: undefined,
      settings: {
        id: '',
        selectedPrinter: {},
        name: '',
        ipAddress: '',
        port: '9100',
        printerName: '',
        font: '',
        size: '',
        lineLength: '',
        width: ''
      },
      fonts: ['A', 'B', 'C'],
      sizes: [0, 1, 2, 3],
      widths: ['58', '80', 'A4'],
      printerToEdit: null,
      installedPrinters: [],
      charTable: null,
      charTables: [],
      codePage: null,
      codePages: [],
      euroSymbolType: null,
      euroSymbolTypes: []
    }
  },

  computed: {
    valid () {
      if (this.isA4) {
        return this.settings.selectedPrinter !== '' && this.settings.name !== ''
      } else {
        return this.settings.selectedPrinter !== '' &&
          this.settings.name !== '' &&
          this.settings.width !== '' &&
          this.settings.font !== '' &&
          this.settings.size !== '' &&
          this.settings.lineLength !== '' &&
          this.settings.charTable !== '' &&
          this.settings.codePage !== '' &&
          this.settings.euroSymbolType !== ''
      }
    },
    isA4 () {
      return this.settings.width === 'A4'
    }
  },

  watch: {
    settings: {
      immediate: true,
      deep: true,
      handler (newValue, _) {
        if (newValue.width === 'A4') {
          this.settings.font = ''
          this.settings.size = ''
          this.settings.lineLength = ''
          this.charTable = ''
          this.codePage = ''
          this.euroSymbolType = ''
        }
      }
    }
  },

  methods: {
    open (printer) {
      this.printerToEdit = printer
      this.fillForm()
      this.visible = true
    },
    close () {
      this.clearForm()
      this.visible = false
    },
    editPrinter () {
      const installedPrinter = this.installedPrinters.find(p => p.name === this.printerToEdit.printer_name)

      const printer = {
        id: this.settings.id,
        name: this.settings.name,
        ipAddress: this.settings.ipAddress,
        port: this.settings.port,
        font: this.settings.font,
        size: this.settings.size,
        lineLength: this.settings.lineLength,
        width: this.settings.width,
        charTable: this.charTable,
        codePage: this.codePage,
        euroSymbolType: this.euroSymbolType,
        library: 'UNIVERSAL'
      }
      if (installedPrinter) {
        printer.printerName = this.settings.printer_name
      } else {
        printer.printerName = this.printerToEdit.printer_name
      }
      this.$emit('editPrinter', printer)
      this.clearForm()
      this.visible = false
    },

    fillForm () {
      this.settings.id = this.printerToEdit.id
      this.settings.name = this.printerToEdit.name
      this.settings.selectedPrinter = this.printerToEdit.printer_name
      this.settings.width = this.printerToEdit.width
      this.settings.font = this.printerToEdit.font
      this.settings.size = this.printerToEdit.size
      this.settings.lineLength = this.printerToEdit.line_length
      this.charTable = this.printerToEdit.char_table
      this.codePage = this.printerToEdit.code_page
      this.euroSymbolType = this.printerToEdit.euro_symbol_type
    },

    clearForm () {
      this.settings.name = ''
      this.settings.ipAddress = ''
      this.settings.port = '9100'
      this.settings.printerName = ''
      this.settings.width = ''
      this.settings.font = ''
      this.settings.size = ''
      this.settings.lineLength = ''
      this.codePage = ''
      this.charTable = ''
      this.euroSymbolType = ''
    },

    calculateLineLength () {
      if (this.settings.font !== null && this.settings.size !== null) {
        switch (this.settings.font.toLowerCase()) {
          case 'a':
            switch (this.settings.size) {
              case 0:
                this.settings.lineLength = 48
                break
              case 1:
                this.settings.lineLength = 24
                break
              case 2:
                this.settings.lineLength = 16
                break
              case 3:
                this.settings.lineLength = 12
                break
            }
            break
          case 'b':
          case 'c':
            switch (this.settings.size) {
              case 0:
                this.settings.lineLength = 64
                break
              case 1:
                this.settings.lineLength = 32
                break
              case 2:
                this.settings.lineLength = 21
                break
              case 3:
                this.settings.lineLength = 16
                break
            }
            break
        }
      }
    },

    initPrintManager () {
      const me = this
      JSPM.JSPrintManager.auto_reconnect = false
      JSPM.JSPrintManager.license_url = state.getJsPrintManagerLicenceUrl()

      JSPM.JSPrintManager.start()
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        if (jspmWSStatus()) {
          me.getPrinters()
        }
      }
    },

    async getPrinters () {
      const me = this
      const wsStatus = await jspmWSStatus()
      // console.log('wsStatus: ', wsStatus)
      if (wsStatus) {
        // get client installed printers
        JSPM.JSPrintManager.getPrinters().then(function (printers) {
          printers.forEach(printer => {
            me.installedPrinters.push(printer)
          })
        })
      }
    }
  },

  async created () {
    await this.initPrintManager()

    this.charTables = [
      { name: 'PC437: USA, Standard Europe', value: 0 },
      { name: 'PC850: Multilingual', value: 2 },
      { name: 'WPC1252', value: 16 },
      { name: 'PC852: Latin 2', value: 18 },
      { name: 'PC858: Euro', value: 19 },
      { name: 'ISO8859-2: Latin 2', value: 39 },
      { name: 'ISO8859-15: Latin 9', value: 40 },
      { name: 'WPC1250: Latin 2', value: 45 },
      { name: 'WPC1253', value: 47 }
    ]

    this.codePages = [
      { name: '437 - OEM United States', value: 437 },
      { name: '500 - IBM EBCDIC International', value: 500 },
      { name: '850 - OEM Multilingual Latin 1; Western European (DOS)', value: 850 },
      { name: '852 - OEM Latin 2; Central European (DOS)', value: 852 },
      { name: '858 - OEM Multilingual Latin 1 + Euro symbol', value: 858 },
      { name: '870 - IBM EBCDIC Multilingual/ROECE (Latin 2)', value: 870 },
      { name: '1250 - Windows Central Europe', value: 1250 },
      { name: '1252 - Windows Latin I', value: 1252 }
    ]

    this.euroSymbolTypes = [
      { name: 'Euro symbol (€)', value: '€' },
      { name: 'EUR', value: 'EUR' }
    ]
  }
}
</script>
